import styled from 'styled-components';
import GaugeComponent from 'react-gauge-component';
import { calculatePercentage } from 'utils/transformData';
const GaugeChartV2 = props => {
	const { title, limit, value } = props;
	return (
		<GaugeContainer>
			<h2>{title}</h2>
			<div className="centerChart">
				<GaugeComponent
					arc={{
						subArcs: [
							{
								limit: 25,
								color: '#62d058',
								showTick: true,
							},
							{
								limit: 50,
								color: '#fae93a',
								showTick: true,
							},
							{
								limit: 75,
								color: '#e37730',
								showTick: true,
							},
							{
								limit: 100,
								color: '#e21b19',
								showTick: true,
							},
						],
					}}
					type="radial"
					pointer={{
						elastic: true,
						animationDelay: 0,
					}}
					value={calculatePercentage(value, limit)}
				/>
			</div>
		</GaugeContainer>
	);
};

const GaugeContainer = styled.div`
	width: 100%;
	padding: 10px;
	h2 {
		font-size: 1rem;
		text-align: center;
	}
	.centerChart {
		display: flex;
		justify-content: center;
		align-items: center;
	}
`;
export default GaugeChartV2;
