import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import api from 'services/api';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import Input from 'components/common/forms/Input';
import Select from 'components/common/forms/Select';
import Submit from 'components/common/forms/Submit';
import Grid from 'components/common/Grid';
import Password from 'components/common/forms/Password';
const EditForm = props => {
	let navigate = useNavigate();
	return (
		<Formik
			initialValues={{
				name: props.name,
				lastName: props.lastName,
				telephone: props.telephone,
				email: props.email,
				password: '',
				discount: `${props.discount}`,
				role: 'seller',
				createdByAdmin: true,
				lenguaje: 'es',
				partnerMembership: props.partner?.membership || '',
				capacity: props.capacity,
				netPremium: props.netPremium,
				memberships: props.memberships,
			}}
			validationSchema={Yup.object({
				name: Yup.string().required('Campo requerido'),
				telephone: Yup.string()
					.min(10, 'Mínimo 10 caracteres')
					.max(10, 'Máximo 10 caracteres')
					.required('Campo requerido'),
				email: Yup.string().email('Correo inválido').required('Campo requerido'),
				password: Yup.string().min(6, 'Mínimo 6 caracteres'),
				confirmPassword: Yup.string().oneOf(
					[Yup.ref('password'), null],
					'Las contraseñas deben coincidir'
				),
			})}
			onSubmit={(values, { setSubmitting }) => {
				setSubmitting(true);
				if (values.password === '') {
					delete values.password;
				}
				api
					.patch(`/user/${props.id}`, values)
					.then(res => {
						toast.success('Guia editado correctamente');
						navigate('/admin/guides');
					})
					.catch(err => {
						if (err.response.data.message === 'El correo ya está registrado') {
							toast.error('El correo ya está registrado');
						} else if (err.response.data.message === 'El teléfono ya está registrado') {
							toast.error('El teléfono ya está registrado');
						} else {
							toast.error('Error inesperado');
						}
						setSubmitting(false);
					});
			}}>
			{formik => (
				<>
					<MyForm onSubmit={formik.handleSubmit}>
						<Grid columns="2">
							<Input
								name="name"
								type="text"
								label="Nombre"
								placeholder="Ingresa tu nombre"
							/>
							<Input
								name="lastName"
								type="text"
								label="Apeliddo"
								placeholder="Ingresa tu nombre"
							/>
							<Input
								name="telephone"
								type="number"
								label="Número de WhatsApp"
								placeholder="Ingresa tu número de WhatsApp"
							/>
							<Input
								name="email"
								type="email"
								label="Correo electrónico"
								placeholder="Ingresa tu correo electrónico"
							/>
							<Password
								name="password"
								type="password"
								label="Contraseña"
								placeholder="Ingresa tu nueva contraseña"
							/>
							<Password
								name="confirmPassword"
								type="password"
								label="Confirma tu contraseña"
								placeholder="Confirma tu contraseña"
							/>
							<Select
								name="discount"
								label="Cantidad de descuento"
								setFieldValue={formik.setFieldValue}
								options={[
									{ value: '0', label: '0%' },
									{ value: '5', label: '5%' },
									{ value: '10', label: '10%' },
									{ value: '15', label: '15%' },
									{ value: '20', label: '20%' },
								]}
							/>
							<Input
								name="partnerMembership"
								type="text"
								label="Aliado"
								placeholder="Aliado"
							/>
							<Input
								name="capacity"
								type="text"
								label="Capacidad"
								placeholder="Capacidad"
							/>
							<Input
								name="netPremium"
								type="text"
								label="Prima Neta"
								placeholder="Prima Neta"
							/>
							<Input
								name="memberships"
								type="text"
								label="Membresias"
								placeholder="Membresias"
							/>
						</Grid>
						<Msg>Puedes dejar en blanco la contraseña si no es necesario modificarla</Msg>
						<Submit
							type="submit"
							margin="3em auto 0 auto "
							disabled={formik.isSubmitting}>
							Editar
						</Submit>
					</MyForm>
				</>
			)}
		</Formik>
	);
};

const MyForm = styled.form`
	padding: 1em 0 2em 0;
	width: 100%;
`;
const Msg = styled.div`
	width: 100%;
	margin-top: 1em;
	text-align: right;
`;
export default EditForm;
