import { useDashboardData } from '../../hooks/useDashoardData';
import Goals from 'components/dashboards/goals';
import Grid from 'components/common/Grid';
import Skeleton from 'components/common/Skeleton';
const Dashboard = () => {
	const { isLoading, response } = useDashboardData();
	return (
		<>
			{isLoading && (
				<Grid columns="1">
					<Skeleton count={30} />
				</Grid>
			)}
			{!isLoading && (
				<>
					<Goals {...response} />
				</>
			)}
		</>
	);
};
export default Dashboard;
