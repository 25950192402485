import Grid from 'components/common/Grid';
import GaugeV2 from 'components/common/charts/gaugev2';
const Goals = props => {
	const { capacity, montoFinanciamiento, netPremium, primaNeta, memberships, polices } = props;
	console.log(props);
	return (
		<Grid columns="3">
			<GaugeV2
				title="Capacidad"
				value={montoFinanciamiento}
				limit={capacity}
			/>
			<GaugeV2
				title="Prima neta"
				value={primaNeta}
				limit={netPremium}
			/>
			<GaugeV2
				title="Membresías"
				value={polices}
				limit={memberships}
			/>
		</Grid>
	);
};
export default Goals;
